.modal-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .7);
    z-index: $modal-z-index;
    overflow-y: auto;

    > div.modal {
        display: block;
        margin: auto;
        height: 100%;
        width: 100%;
        background: $white;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.35);
        overflow: hidden;
        position: relative;
        pointer-events: auto;
        @include tablet() {
            max-width: 500px;
            margin: 80px auto;
            height: auto;

            &.success {
                max-width: 500px !important;
            }
        }
        
        @media screen and (max-height: 700px) and (min-width: 992px){
            margin: 0px auto;
            -ms-transform: scale(.85); /* IE 9 */
            -webkit-transform: scale(.85); /* Safari */
            transform: scale(.85); /* Firefox, Opera, Chrome */
            
        }


        &.success {
            height: auto;
            margin: 80px auto;
            max-width: 300px;
        }

        > header {
            background: $primary;
            padding: 0.5em 3em 1.3em 1em;
            display: block;
            color: $white;
            height: auto;
            position: relative;
            text-align: left;
            @include tablet() {
                padding: 1em 3em 2em 2em;
            }

            > section {
                > h2 {
                    font-size: 1.8em;
                    margin: 0.5em 0 0.2em;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                > h3 {
                    margin-bottom: 0;
                }

                > span {
                    font-size: 1.1em;
                    font-weight: 500;

                    &.category {
                        display: block;
                        color: lighten($primary, 20%);
                        font-size: 1.3em;
                        font-weight: 700;
                        margin-bottom: 1.2em;
                    }

                    &.location,
                    &.separator,
                    &.type {
                        color: #3D5668;
                    }
                }

                > i.close {
                    position: absolute;
                    top: 0.5em;
                    right: 0.5em;
                    cursor: pointer;
                    color: lighten($primary, 15%);
                    font-size: 2em;
                    @include tablet() {
                        display: none;
                    }
                }

                &.success {
                    text-align: center;
                }
            }
        }

        > div.main {
            padding: 1em;
            overflow: auto;
            height: calc(100% - 60px - 120px);
            @include tablet() {
                padding: 2em;
                height: auto;
            }

            section {
                div.tooltip-info {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(#000, .4);
                    z-index: $modal-z-index + 10;

                    .tooltip-modal {
                        display: block;
                        width: 100%;
                        max-width: 33em;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        padding: 2em 3em 2em 2em;
                        background: $white;
                        border: grey;
                        border-radius: 3px;
                        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.35);

                        & > p {
                            margin: 0;
                        }

                        & > ul {
                            margin-left: -1.5em !important;
                        }

                        > div.tooltip-info-closer {
                            position: absolute;
                            top: 0;
                            right: 0;
                            height: 46px;
                            width: 46px;

                            > i {
                                height: 100%;
                                width: 100%;
                                text-align: center;
                                line-height: 46px;
                                font-size: 1.2em;
                                display: block;
                                cursor: pointer;
                            }
                        }
                    }
                }

                form.apply-form {
                    div.form-field {
                        margin: 1em 0;
                        @include desktop() {
                            margin: 2em 0;
                        }

                        div.input {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            > .required {
                                color: $error;
                                font-size: 1.2em;
                            }

                            input,
                            select {
                                color: lighten($dark-bg, 30%);
                                padding: 0.5em;

                                &.ng-invalid {
                                    &.ng-valid-required {
                                        color: $error;
                                    }
                                }

                                &[type="email"],
                                &[type="text"] {
                                    border: none;
                                    border-bottom: 1px solid darken($light-bg, 15%);
                                    transition: border-bottom 0.2s ease-in-out;
                                    //padding: 0.5em;
                                    &:focus {
                                        border-bottom: 1px solid $primary;
                                    }
                                }
                            }

                            &.eeoc {
                                flex-direction: column;

                                > label {
                                    margin-bottom: 0.5em;
                                    text-align: left;
                                    width: 100%;
                                    font-size: 1.1em;
                                    color: rgb(121, 124, 126);
                                }

                                select {
                                    border-color: #adafb0;
                                    border-radius: 4px;

                                    &:focus {
                                        outline: none;
                                    }
                                }

                                fieldset {
                                    width: 100%;
                                    border-radius: 3px;
                                    border-color: rgba(#000, .1);
                                }

                                .eeoc-checkbox {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    height: 36px;

                                    input[type="checkbox"] {
                                        margin-top: 0;
                                        flex-shrink: 0;
                                        width: initial;
                                        height: 36px;
                                    }

                                    label {
                                        height: 36px;
                                        line-height: 36px;
                                        padding-left: 10px;
                                        flex: 1;
                                        width: 100%;
                                    }
                                }
                            }
                        }

                        &.upload-container {
                            border: 1px solid $primary;
                            color: $primary;
                            border-radius: 3px;
                            background: transparent;
                            text-align: center;
                            position: relative;
                            cursor: pointer;
                            font-size: 1.2em;
                            margin-top: 1em;
                            margin-bottom: 1.6em;
                            height: 50px;

                            > i {
                                line-height: 50px;
                                font-size: 1.3em;
                                padding-right: 0.5em;

                                &:before {
                                    vertical-align: inherit;
                                }
                            }

                            > span {
                                line-height: 50px;
                                vertical-align: top;
                            }

                            &.valid {
                                border-color: $secondary;
                                color: $secondary;
                            }
                        }

                        &:first-child {
                            margin-top: 0;
                        }
                    }

                    > div.upload-label {
                        font-size: 1.1em;
                        color: lighten($dark-bg, 30%);

                        > i {
                            color: lighten($dark-bg, 20%);
                        }

                        > .error {
                            margin-top: 0;
                            float: right;
                        }
                    }
                }

                &.success {
                    padding: 1em;
                }

                select {
                    width: 100%;
                    overflow: hidden;
                }

                input {
                    color: lighten($dark-bg, 30%);
                    width: 100%;
                    margin-top: 1em;

                    &.ng-invalid {
                        &.ng-valid-required {
                            color: $error;
                        }
                    }

                    &[type="email"],
                    &[type="text"] {
                        border: none;
                        border-bottom: 1px solid darken($light-bg, 15%);
                        transition: border-bottom 0.2s ease-in-out;
                        padding: 0.5em;

                        &:focus {
                            border-bottom: 1px solid $primary;
                        }
                    }
                }
                // Linked In Skills and expertise:
                textarea {
                    width: 100%;
                    resize: none;
                    border: none;
                    border-bottom: solid thin #CCC;
                    padding: 0 0.75em;
                    color: #797c7e;

                    &:active,
                    &:focus,
                    &:hover {
                        outline: none;
                    }

                    &:focus {
                        border-bottom-color: $primary;
                    }
                }
            }
        }

        footer {
            width: 100%;
            text-align: right;
            position: fixed;
            bottom: 0;
            left: 0;
            height: 50px;
            display: flex;
            flex-flow: row nowrap;
            @include tablet() {
                position: relative;
            }
            @include phone() {
                position: relative;
            }
            @include small-phone() {
                position: relative;
            }

            &.success {
                text-align: right;
                padding-top: 0;
                display: inline-block;
                position: relative;

                button {
                    background-color: transparent !important;
                    text-align: right;
                    color: $primary !important;
                }
            }

            button {
                text-transform: uppercase;
                line-height: 50px;
                font-size: 1.5em;
                border: none;
                width: 49%;
                font-weight: 700;
                flex: 1;

                &[name="cancel"],
                &[name="ok"] {
                    color: $cancel;
                    background: transparent;
                    font-weight: 600;
                    display: none;
                    @include tablet() {
                        display: inline-block;
                    }
                }

                &[name="submit"] {
                    color: $white;
                    background: $secondary;
                    border-radius: 2px;
                    width: 100%;
                    font-weight: 700;

                    > i {
                        float: right;
                    }
                }

                &[name="ok"] {
                    flex: 0;
                    float: right;
                    color: $primary;
                    font-size: 1em;
                    width: 200px;
                    display: inline-block;
                }

                &.disabled {
                    background-color: lightgray;
                }
            }
        }
    }
}

.error {
    display: block;
    color: $error;
    margin-left: 1.1em;
    margin-top: 0.3em;

    &.submit-error {
        margin-bottom: 1em;
    }
}

.accepted-formats {
    ._720kb-tooltip-title {
        font-size: 0.8em;
    }

    ul {
        margin: 0.2em 0 0 1.1em;
        font-size: 0.9em;
        padding: 0;
    }
}

.loading {
    text-align: center;

    .loading-dot {
        display: inline-block;
        width: 5px;
        height: 5px;
        background: white;
        border-radius: 50%;
        animation: loading 1.5s ease-in-out infinite;
    }

    .loading-dot:nth-child(4n + 1) {}

    .loading-dot:nth-child(4n + 2) {
        animation-delay: 0.3s;
    }

    .loading-dot:nth-child(4n + 3) {
        animation-delay: 0.6s;
    }
}
@keyframes loading {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}
